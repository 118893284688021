import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import App from '../../app/app'

const FirebasePage = () => {

  return <Layout>
    <SEO title="Firebase-Test" keywords={[]}/>
    <h1>Firebase-Test App</h1>
    <App/>
  </Layout>
}


export default FirebasePage
